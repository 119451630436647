.orders {
  .badge {
    font-size: 13px;
  }
  .star {
    color: var(--yellow);
  }
  .rate-modal {
    .stars {
      text-align: center;
    }
  }
}
