.changable-img {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
  position: relative;
  border-radius: 50%;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .img-change {
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #eeeeee7d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: var(--dark);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.1s;
    input {
      appearance: none;
      display: none;
    }
    label {
      cursor: pointer;
    }
  }
  &:hover {
    .img-change {
      opacity: 1;
    }
  }
}
.form-input {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  margin: 20px 0;
  label {
    margin-right: 10px;
  }
  @include responsive-desktop {
    max-width: 100%;
  }
  input,
  select,
  textarea {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    width: 100%;
    padding: 8px 5px;
    border: 1px solid #eee;
    border-radius: 5px;
    &:focus {
      outline: none;
      border-color: var(--green);
    }
  }
  small {
    color: var(--red);
  }
}
.form-container {
  max-width: 800px;
  margin: 0 auto;
}
