.wrapper {
  display: flex;
}

main.main {
  transition: margin-left 0.3s;
  flex-grow: 1;
  overflow-x: hidden;
  margin-left: 280px;
  min-height: 100vh;
  padding: 10px 25px;
  overflow-x: auto;
  @include responsive-desktop {
    flex-basis: calc(100% - 70px);
    margin-left: 70px;
  }
}
.minimal {
  main.main {
    flex-basis: calc(100% - 70px);
    margin-left: 70px;
  }
}

.page-title {
  font-size: 25px;
  margin: 30px 0;
}

.only-btn-icon-mobile {
  span {
    margin-left: 5px;
    @include responsive-mobile {
      display: none;
    }
  }
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.o-zere {
  opacity: 0;
}

.text-danger {
  color: var(--red);
}
