.dashboard {
  padding: 30px 0;
  .stats {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .stat-card {
      flex-basis: 22%;
      flex-shrink: 0;
      background-color: var(--light-dark);
      color: white;
      box-shadow: var(--ligh-shadow);
      border-radius: 5px;
      padding: 22px 15px;
      margin: 10px 0;
      position: relative;

      @include responsive-desktop {
        flex-basis: 48%;
      }
      @include responsive-tablet {
        flex-basis: 100%;
      }
      p {
        opacity: 0.9;
        font-size: 14px;
        text-transform: uppercase;
        margin: 0;
      }
      h3 {
        margin: 5px 0 0 0;
        font-size: 26px;
      }
      .icon {
        position: absolute;
        right: 30px;
        top: 22px;
        font-size: 20px;
        width: 60px;
        height: 60px;
        line-height: 60px;
        background: var(--dark);
        text-align: center;
        border-radius: 50%;
      }
    }
  }

  &-box {
    background-color: #fff;
    margin: 30px 0;
    padding: 20px;
    border-radius: 5px;
    box-shadow: var(--ligh-shadow);

    .title {
      font-size: 20px;
      margin: 0 0 20px 0;
      font-family: "Open Sans";
      color: var(--dark-dark);
    }
  }

  .tables {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(550px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    min-height: 0;
    min-width: 0;

    & > .dashboard-box {
      overflow: hidden;
      min-width: 0;
    }

    @include responsive-mobile {
      grid-template-columns: 1fr;
    }
  }
}
