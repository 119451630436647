.alter {
  margin: 10em 0 0 20px;
  position: relative;
  h3 {
    font-size: 40px;
    margin-bottom: 40px !important;
  }
  a,
  button {
    display: inline-block;
    background-color: var(--green);
    padding: 12px 35px;
    font-size: 17px;
    border-radius: 5px;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    transition: background-color 0.3s;
    &:hover {
      background-color: var(--green-dark);
    }
  }
}
