.user-settings,
.payment-settings {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .content {
    .form {
      .user-img {
        @include responsive-mobile {
          width: 100%;
        }
      }
      .form-input {
        max-width: 600px;
      }
      button {
        padding: 10px 30px;
        i {
          margin-right: 3px;
        }
      }
    }
    .payment {
      display: flex;
      align-items: center;
      background-color: white;
      box-shadow: 0 0 15px 5px #26465312;
      margin: 10px 0;
      padding: 25px 15px;
      border-radius: 5px;
      width: 400px;
      position: relative;

      @include responsive-mobile {
        width: 100%;
      }
      h4 {
        margin: 0 10px;
      }
      i {
        color: var(--red);
        cursor: pointer;
        position: absolute;
        right: 15px;
        justify-self: flex-end;
      }
    }
  }
}
