.badge {
  font-weight: bold;
  color: white;
  padding: 5px 15px;
  border-radius: 3px;
  display: inline-block;
  margin: 5px 0;

  &-primary {
    background-color: var(--dark);
  }
  &-secondary {
    background-color: var(--secondary);
  }
  &-danger {
    background-color: var(--red);
  }
  &-warning {
    background-color: var(--yellow);
  }
  &-orange {
    background-color: var(--orange);
  }
  &-info {
    background-color: var(--blue);
  }
  &-success {
    background-color: var(--green);
  }

  &-shipped {
    @extend .badge-danger;
  }
  &-arrived {
    @extend .badge-warning;
  }
  &-delivered {
    @extend .badge-success;
  }
}
