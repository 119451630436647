.login {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include responsive-desktop {
    grid-template-columns: 1fr;
  }
  .bannar {
    background: linear-gradient(45deg, var(--dark-dark), transparent), url("../../img/bannar.jpg");
    min-height: 100vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: #fff;
    @include responsive-desktop {
      min-height: 60vh;
    }
  }
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      font-size: 2rem;
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 70%;
      .form-input {
        width: 100%;
      }
      button {
        width: 100%;
      }
    }
  }
}
