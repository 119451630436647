:root {
  --dark: #425f7c;
  --light-dark: #5e7fa0;
  --dark-dark: #0f3357;
  --secondary: #bebebe;
  --secondary-dark: #9e9e9e;
  --green: #009688;
  --green-dark: #017e72;
  --yellow: #f4c758;
  --yellow-dark: #e6bb52;
  --orange: #ff9800;
  --orange-dark: #e58903;
  --red: #e54034;
  --red-dark: #d03a2f;
  --blue: #2196f3;
  --blue-dark: #1f82d0;
  --ligh-shadow: 0 3px 20px 12px rgb(66 95 124 / 10%);
  // --blue-shadow: 0 3px 20px 12px rgb(244 249 255 / 10%);
}
