.table {
  font-size: 15px;
  width: 100%;
  border-collapse: collapse;

  &-responsive {
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  tr {
    border-bottom: 1px solid #e2ebf7;
    border-top: 1px solid #e2ebf7;
  }
  thead {
    background-color: #5e7fa0;
    color: #fff;
  }
  tbody {
    tr {
      transition: all 0.2s;
      &:hover {
        background-color: #e2ebf7;
      }
    }
  }
  th,
  td {
    text-align: center;
    padding: 10px 20px;
    text-overflow: clip;
    overflow: hidden;
    white-space: nowrap;
  }
  .rate {
    .fas {
      color: var(--yellow);
    }
    .far {
      color: var(--dark);
    }
    a {
      font-size: 15px;
      color: var(--dark);
    }
  }
}
