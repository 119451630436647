.whatisnew {
  .content {
    .version {
      padding: 20px 15px;
      color: var(--dark);
      &:not(:last-of-type) {
        border-bottom: 1px solid #eee;
      }
      h4 {
        font-size: 22px;
        margin: 10px 0;
        color: var(--green);
        span {
          font-size: 13px;
          color: var(--green-dark);
        }
      }
      ul {
        list-style: none;
        padding-left: 30px;
        li {
          margin: 15px 0;
          span {
            border-radius: 5px;
            margin-right: 8px;
            padding: 3px;
            display: inline-block;
            text-align: center;
            width: 90px;
            font-size: 14px;
            color: white;
            text-transform: capitalize;
            &.feature {
              background-color: var(--blue);
            }
            &.improve {
              background-color: var(--green);
            }
            &.fix {
              background-color: var(--red);
            }
          }
        }
      }
    }
  }
}
