.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  .card {
    background-color: #fff;
    box-shadow: var(--ligh-shadow);
    border-radius: 10px;
    &-img {
      position: relative;
      height: 200px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px 10px 0 0;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px 10px 0 0;
      }
      .icon {
        position: absolute;
        top: 10px;
        left: 10px;
        background-color: #eee;
        color: var(--red);
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;
      }
    }
    &-content {
      padding: 15px 15px;
      h4 {
        color: var(--dark);
        font-size: 18px;
        margin: 0;
      }
    }
    &-footer {
      padding: 15px 15px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      .price {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        color: var(--dark);
      }
    }
  }
}
