.list-container {
  list-style: none;
  padding: 0;
  margin: 0;
  .list-item {
    display: grid;
    grid-template-columns: 3fr 2fr 1fr 1fr;
    align-items: center;
    background-color: #fff;
    box-shadow: var(--ligh-shadow);
    margin: 20px 0;
    padding: 15px 18px;
    border-radius: 15px;
  }
}
