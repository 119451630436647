.menu {
  background-color: #f4f9ff;
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;

  @include responsive-mobile {
    flex-direction: column;
    justify-content: center;
  }
  img {
    width: 35px;
  }
  .collapse {
    margin: 0 30px 0 20px;
    cursor: pointer;
    @media (max-width: 992px) {
      visibility: hidden;
    }
  }
  .list {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    color: var(--dark);

    @include responsive-mobile {
      margin-top: 20px;
    }
    .dropdown {
      margin: 0 2px;
      position: relative;
      ul {
        @include responsive-mobile {
          right: 0;
          left: -40px;
          &::before {
            right: 0;
            left: 50px;
          }
        }
      }
      & > li {
        cursor: pointer;
        font-size: 16px;
        margin: 0 10px;
        position: relative;
        span {
          position: absolute;
          background-color: var(--red);
          font-size: 11px;
          font-weight: 600;
          padding: 2px 6px;
          color: white;
          border-radius: 50%;
          top: -7px;
          right: -10px;
        }
      }
      &-content {
        z-index: 100;
        position: absolute;
        background-color: var(--dark);
        color: white;
        width: 300px;
        right: -30px;
        top: 36px;
        border-radius: 5px;
        box-shadow: 0 0 20px 20px #2646531c;

        @include responsive-mobile {
          left: -40px;
          width: 240px;
        }
      }
    }
    .alter {
      padding: 10px;
      margin: 0;
    }
    .notification {
      .dropdown-content {
        ul {
          list-style: none;
          padding: 10px 0;
          &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-bottom: 12px solid var(--dark);
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            top: -10px;
            right: 38px;
          }
          li {
            border-bottom: 1px solid #ffffff21;
            padding: 10px 10px;
            position: relative;
            &:last-of-type {
              border-bottom: 0;
            }
            .close {
              position: absolute;
              font-size: 12px;
              right: 8px;
              padding: 0;
              width: 20px;
              height: 20px;
              line-height: 20px;
              text-align: center;
              background-color: var(--red);
              color: white;
              border-radius: 50%;
              top: 11px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .shopping-cart {
      .dropdown-content {
        width: 400px;
        @include responsive-mobile {
          width: 280px;
        }
        ul {
          list-style: none;
          padding: 10px 0;
          @include responsive-mobile {
            width: 280px;
          }
          &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-bottom: 12px solid var(--dark);
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            top: -10px;
            right: 38px;
          }
          li {
            border-bottom: 1px solid #ffffff21;
            padding: 10px 5px;
            position: relative;
            &:last-of-type {
              border-bottom: 0;
            }
            .close {
              position: absolute;
              font-size: 12px;
              right: 8px;
              top: 35px;
              padding: 0;
              width: 20px;
              height: 20px;
              line-height: 20px;
              background-color: var(--red);
              color: white;
              border-radius: 50%;
              text-align: center;
              cursor: pointer;
            }
            .item {
              display: flex;
              &-img {
                width: 80px;
                margin-right: 5px;
                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 5px;
                }
                @include responsive-mobile {
                  display: none;
                }
              }
              &-details {
                width: 100%;
                h4 {
                  margin: 5px 0;
                }
                p {
                  margin: 0;
                }
              }
              .counter {
                position: relative;
                width: 50px;
                height: 30px;
                top: 20px;
                right: 40px;
                border-radius: 3px;
                border: 0;
                padding: 5px 10px;
                &:focus {
                  outline: none;
                }
              }
            }
          }
        }
        footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: white;
          padding: 10px 20px;

          @include responsive-mobile {
            padding: 10px;
          }
          p {
            margin: 0;
            color: var(--dark);
            font-size: 20px;
          }
          .btn {
            padding: 5px 30px;
          }
        }
      }
    }
  }
  .user {
    display: flex;
    align-items: center;
    margin-left: 8px;
    img {
      border-radius: 50%;
    }
    h3 {
      font-size: 16px;
      margin: 0 5px;
    }
    .more-option {
      .icon {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;
        &:hover {
          background: #e9e9e9;
        }
      }
      .dropdown-content {
        ul {
          list-style: none;
          padding: 7px 0;
          li {
            a {
              padding: 3px 10px;
              margin: 3px 0;
              display: block;
              color: #fff;
              text-decoration: none;
            }
            &:hover {
              background-color: var(--light-dark);
            }
          }
        }
      }
    }
  }
}
