.gallery {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .gallery-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    grid-auto-rows: minmax(300px, auto);
    grid-auto-flow: dense;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin: 0 auto;
    padding: 0;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    .gallery-item {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 30px auto;
  }
}
