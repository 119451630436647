.delivery {
  .badge {
    font-size: 13px;
  }
  .table {
    button {
      padding: 0;
    }
  }
}
