.products {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .new-product {
    .content {
      width: 800px;
      @include responsive-tablet {
        width: 90%;
      }
    }
    form {
      display: grid;
      grid-template-columns: 1fr 2fr;

      @include responsive-mobile {
        grid-template-columns: 1fr;
      }
    }
  }
}
