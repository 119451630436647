.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #26465363;
  z-index: 100;

  .content {
    position: relative;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    background-color: white;
    border-radius: 5px;
    padding: 10px 15px;
    @include responsive-tablet {
      width: 90%;
    }
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      padding: 8px 5px;
      h3 {
        margin: 8px 0;
      }
      i {
        color: var(--dark) !important;
        font-size: 20px;
        cursor: pointer;
      }
    }
    .main {
      border-top: 1px solid #f1f1f1;
      border-bottom: 1px solid #f1f1f1;
      padding: 22px 0;
      .stars {
        i {
          font-size: 30px;
        }
      }
    }
    footer {
      display: flex;
      margin-top: 10px;
      justify-content: space-between;
      padding: 8px 5px;
      button {
        flex-basis: 48%;
      }
    }
  }
}

@media (max-width: 576px) {
  .popup {
    .content {
      width: 90%;
      header {
        font-size: 15px;
        h3 {
          width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      footer {
        button {
          font-size: 15px;
        }
      }
    }
  }
}
