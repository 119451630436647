@mixin responsive-mobile {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin responsive-tablet {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin responsive-desktop {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin responsive-large {
  @media (max-width: 1200px) {
    @content;
  }
}
