.components {
  header {
    h2 {
      font-size: 25px;
      margin-bottom: 30px;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &-section {
    flex-basis: 48%;
    flex-grow: 1;
    margin: 5px 0;

    @include responsive-tablet {
      flex-basis: 100%;
    }
    button {
      margin: 5px;
    }
  }
}
