.navigation {
  width: 280px;
  background-color: var(--dark);
  color: #fff;
  min-height: 100vh;
  height: 100%;
  z-index: 10;
  position: fixed;
  transition: width 0.3s;

  header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    h1 {
      text-align: center;
      font-size: 22px;
      white-space: nowrap;
      a {
        text-decoration: none;
        color: white;
        display: flex;
        align-items: center;
        img {
          width: 40px;
          margin-right: 5px;
        }
      }
    }
  }
  ul {
    list-style: none;
    margin: 20px 0;
    padding: 10px 20px;
    li {
      margin: 6px 0;
      white-space: nowrap;
      overflow: hidden;
      a {
        text-decoration: none;
        color: white;
        font-size: 16px;
        padding: 10px 15px;
        display: block;
        border-radius: 5px;
        &.active {
          background-color: var(--light-dark);
          color: white;
        }
      }
      &.dropdown {
        color: white;
        font-size: 16px;
        position: relative;
        user-select: none;
        overflow: hidden;
        &::after {
          content: "\f054";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          font-size: 14px;
          position: absolute;
          right: 10px;
          top: 13px;
          transform: rotate(0deg);
          transition: transform 0.2s;
          cursor: pointer;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 3px;
        }
        ul {
          margin: 0;
          position: relative;
          li {
            margin: 0;
            a {
              padding: 7px 10px;
              &.active {
                background-color: transparent;
                &::before {
                  content: "\f0da";
                  font-family: "Font Awesome 5 Free";
                  font-weight: 900;
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }
      &.open {
        &::after {
          transform: rotate(90deg);
          transition: transform 0.2s;
          // background-color: #8da1b5;
        }
      }
      i {
        margin-right: 8px;
      }
    }
  }
}

.minimal {
  .navigation {
    width: 70px;
    overflow: hidden;
    header {
      justify-content: center;
      h1 {
        margin-left: 0;
        a {
          span {
            display: none;
          }
        }
      }
      .collapse {
        display: none;
      }
    }
    ul {
      padding: 10px;
      li {
        span {
          display: none;
        }
        i {
          margin-right: 0;
        }
        &.dropdown {
          &::after {
            display: none;
          }
          ul {
            display: none;
            padding: 10px 20px;
          }
        }
      }
    }
    &:hover {
      width: 280px;
      header {
        justify-content: space-between;
        h1 {
          margin-left: 8px;
          a {
            span {
              display: initial;
            }
          }
        }
        .collapse {
          display: initial;
        }
      }
      ul {
        padding: 10px 20px;
        li {
          span {
            display: initial;
          }
          i {
            margin-right: 8px;
          }
          &.dropdown {
            &::after {
              display: initial;
            }
            ul {
              display: block;
            }
          }
        }
      }
    }
  }
}

@include responsive-desktop {
  .navigation {
    width: 70px;
    position: fixed;
    flex-shrink: 0;
    transition: width 0.3s;
    overflow: hidden;
    header {
      justify-content: center;
      h1 {
        margin-left: 0;
        a {
          span {
            display: none;
          }
        }
      }
      .collapse {
        display: none;
      }
    }
    ul {
      padding: 10px;
      li {
        span {
          display: none;
        }
        i {
          margin-right: 0;
        }
        &.dropdown {
          &::after {
            display: none;
          }
          ul {
            display: none;
            padding: 10px 20px;
          }
        }
      }
    }
    &:hover {
      width: 280px;
      header {
        justify-content: space-between;
        h1 {
          margin-left: 8px;
          a {
            span {
              display: initial;
            }
          }
        }
        .collapse {
          display: initial;
        }
      }
      ul {
        padding: 10px 20px;
        li {
          span {
            display: initial;
          }
          i {
            margin-right: 8px;
          }
          &.dropdown {
            &::after {
              display: initial;
            }
            ul {
              display: block;
            }
          }
        }
      }
    }
  }
}
